<template>
  <QuestionBase :question="question" :errors="[...jsErrors]" :valid="!!data.length" :id="db.saveField">
    <div class="question-box with-arrow mt-32">
      <transition name="fade">
        <div class="accordion sub-items">

          <ObjectCard
              v-for="(policy, index) in data"
              v-model="data[index]"
              :ref="'policy_other' + index"
              :id="'policy_other' + index"
              :key="'policy_other' + policy.id"
              :title="`${policy.policyType} (£${policy.policySumAssured})`"
              type="policy-other"
              :loading="loading"
              @delete="deleteObject(policy.id)"
              @save="saveObject(null, 'policy_other' + index)"
          >
            <Component :is="formType" v-model="data[index]" :objectId="policy.id" :key="'policy-form' + policy.id"/>

            <template #customTag>
              <slot name="customTag" :policy="policy"></slot>
            </template>
          </ObjectCard>

          <ObjectCard
              v-if="form"
              v-model="form"
              type="policy-add"
              title="New policy"
              :loading="loading"
              @delete="form=null"
              @save="saveObject(form, db.saveField, null, null, null)"
          >
            <Component :is="'PolicyAppendixNew'" v-model="form" :noSave="true" :errors-post="errorsPost" :objectId="null"/>
          </ObjectCard>

          <b-button
              v-if="!form"
              @click="addPolicy"
              class="btn-question w-100"
          >
            <i class="i-Letter-Open text-25 font-weight-800 mr-2">
            </i>
            Add Policy
          </b-button>
        </div>
      </transition>
    </div>
  </QuestionBase>

</template>

<script>
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PolicyAppendix from "@/components/common/questionnaires/fieldsets/sub/policy/PolicyAppendix";
import PolicyAppendixNew from "@/components/common/questionnaires/fieldsets/sub/policy/PolicyAppendixNew";

export default {
  name: 'PoliciesAll',
  components: {ObjectCard, QuestionBase, PolicyAppendix, PolicyAppendixNew},
  mixins: [objectSaveHelpers, questionLoadHelpers, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'full'
    },
    title: {
      type: String,
      default: 'Policies'
    },
    subTitle: {
      type: String,
      default: 'Please add a few details for each of your policies.'
    },
    tip: {
      type: String,
      default: null
    },
    formType: {
      type: String,
      default: 'PolicyAppendix'
    }
  },
  data() {
    return {
      question: {
        title: this.title,
        subTitle: this.subTitle,
        tip: this.tip,
      },
      db: {
        saveLocation: 'client_policy',
        saveField: 'policies',
        deleteLocation: 'client_policy',
        loadLocation: 'client_policy',
        syncMultipleObjectOnSave: false,
        fetchObjectsOnSave: true
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    addPolicy() {
      this.form = null
      this.form = Object.assign({}, this.policyBlankForm)
    },
  }
}
</script>
