<template>
  <div>
    <Providers
        v-model="form.providers"
        :assetType="form.assetType"
        :errors-post="errorsPost.providers"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>
import Providers from "@/components/common/questionnaires/question/questions/clientAsset/Providers";

export default {
  name: 'AssetAppendix',
  components: {Providers},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
