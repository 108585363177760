<template>
  <div>
    <Provider
        v-model="form.policyProvider"
        :errors-post="errorsPost.policyProvider"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PolicyNumber
        v-model="form.policyNumber"
        :errors-post="errorsPost.policyNumber"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PremiumAmount
        v-model="form.policyPremiumAmount"
        :errors-post="errorsPost.policyPremiumAmount"
        :object-id="objectId"
        :no-save="noSave"
    />

    <StartDate
        v-model="form.policyStartDate"
        :errors-post="errorsPost.policyStartDate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Term
        v-if="form.type !== 'Whole of Life'"
        v-model="form.policyTerm"
        :errors-post="errorsPost.policyTerm"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>

import Provider from "../../../question/questions/clientPolicy/Provider";
import PolicyNumber from "../../../question/questions/clientPolicy/PolicyNumber";
import Term from "@/components/common/questionnaires/question/questions/clientPolicy/Term";
import PremiumAmount from "@/components/common/questionnaires/question/questions/clientPolicy/PremiumAmount";
import StartDate from "@/components/common/questionnaires/question/questions/clientIncome/StartDate";

export default {
  name: 'PolicyAppendix',
  components: {
    StartDate,
    PremiumAmount,
    Term,
    Provider,
    PolicyNumber,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>