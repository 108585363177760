<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Contact Information</h3>
      <ClientPeopleAppendix
          v-model="form.people"
      />
  </div>
</template>

<script>
import {fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";

import ClientPeopleAppendix
  from "@/components/common/questionnaires/question/questions/clientProfile/people/ClientPeopleAppendix";
import {httpQuestionnaire} from "@/services";

export default {
  name: 'AppendixClientPeople',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ClientPeopleAppendix
  },
  data() {
    return {
      label: 'Contact Details', // used for steps progress
      navigateNextFunction: this.clientsContactDone
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    async clientsContactDone() {
      // contact details have been filled out
      // now people in products need updating on the server
      console.log('client contact details done')
      httpQuestionnaire.post('find_people_in_products').then(
          response => {
            console.log(response.data)
          }
      )
      return true
    }
  }
};
</script>
