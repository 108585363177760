<template>
  <div>

    <TypeAppendix
        v-model="form.policyType"
        :errors-post="errorsPost.policyType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Owners
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />

    <OwnerOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />

    <SumAssured
        v-model="form.policySumAssured"
        :policyType="form.policyType"
        :errors-post="errorsPost.policySumAssured"
        :object-id="objectId"
        :no-save="noSave"
    />

    <MortgageRelated
        v-model="form.policyMortgageRelated"
        :errors-post="errorsPost.policyMortgageRelated"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Provider
        v-model="form.policyProvider"
        :errors-post="errorsPost.policyProvider"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PolicyNumber
        v-model="form.policyNumber"
        :errors-post="errorsPost.policyNumber"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PremiumAmount
        v-model="form.policyPremiumAmount"
        :errors-post="errorsPost.policyPremiumAmount"
        :object-id="objectId"
        :no-save="noSave"
    />

    <StartDate
        v-model="form.policyStartDate"
        :errors-post="errorsPost.policyStartDate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Term
        v-if="form.type !== 'Whole of Life'"
        v-model="form.policyTerm"
        :errors-post="errorsPost.policyTerm"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>

import OwnerOtherDetails from "../../../question/questions/clientPolicy/OwnerOtherDetails";
import Owners from "../../../question/questions/clientPolicy/Owners";
import Provider from "../../../question/questions/clientPolicy/Provider";
import PolicyNumber from "../../../question/questions/clientPolicy/PolicyNumber";
import Term from "@/components/common/questionnaires/question/questions/clientPolicy/Term";
import PremiumAmount from "@/components/common/questionnaires/question/questions/clientPolicy/PremiumAmount";
import StartDate from "@/components/common/questionnaires/question/questions/clientIncome/StartDate";
import SumAssured from "@/components/common/questionnaires/question/questions/clientPolicy/SumAssured";
import MortgageRelated from "@/components/common/questionnaires/question/questions/clientPolicy/MortgageRelated";
import TypeAppendix from "@/components/common/questionnaires/question/questions/clientPolicy/TypeAppendix";

export default {
  name: 'PolicyAppendixNew',
  components: {
    TypeAppendix,
    MortgageRelated,
    SumAssured,
    StartDate,
    PremiumAmount,
    Term,
    Owners,
    OwnerOtherDetails,
    Provider,
    PolicyNumber,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>