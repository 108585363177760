<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Policy Information</h3>

    <div v-if="form.policies">
      <PoliciesAll
          ref="policiesOther"
          type="estatePlanning"
          v-model="form.policies"
          title="Please add a few details about your insurance arrangements (if you don't have the details to hand, they can be added later):"
          sub-title="This ensures that executors are aware of any insurance policies allowing them to claim accordingly (the ABI estimate that over £2bn in life insurance funds remain unclaimed)."
          form-type="PolicyAppendix"
      >
        <template v-slot:customTag="{policy}">
          <div v-dompurify-html="policyTagDescription(policy)"
               class="badge p-2"
               :class="policyTagClass(policy)"
               style="position: absolute;bottom: -9px;right: 5%">
          </div>
        </template>
      </PoliciesAll>
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import PoliciesAll from "@/components/common/questionnaires/question/questions/clientProfile/policies/PoliciesAll";

export default {
  name: 'AppendixClientPolicies',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PoliciesAll
  },
  data() {
    return {
      label: 'Policy Information' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    policyTagDescription(policy) {
      if (policy.policyNumber || policy.policyProvider) {
        let provider = policy.policyProvider ? policy.policyProvider : 'Provider missing'
        let number = policy.policyNumber ? policy.policyNumber : 'Reference missing'
        return `<i class="i-Affiliate font-weight-bold text-12 mr-2"></i>${provider}<i class="i-Password-Field text-12 font-weight-bold ml-2 mr-2"></i>${number}`
      } else if (!policy.policyProvider && !policy.policyNumber) {
        return 'No policy provider or reference'
      }
    },
    policyTagClass(policy) {
      if (!policy.policyProvider && !policy.policyNumber) return 'badge-danger'
      else if (!policy.policyProvider || !policy.policyNumber) return 'badge-warning'
      return 'badge-success'
    }
  }
};
</script>
