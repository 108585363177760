<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">
    <div class="question-box with-arrow mt-32">
      <transition name="fade">
        <div class="accordion sub-items">

          <ObjectCard v-for="(person, index) in data"
                      :id="'person'+index"
                      :key="'person' + person.id"
                      :ref="'person'+index"
                      v-model="data[index]"
                      :title="`${fullName(person)} (${ person.relationship_to_client})`"
                      type="person"
                      :loading="loading"
                      :show-delete="false"
                      @delete="deleteObject(person.id)"
                      @save="saveObject(person, 'person'+index, null, null, person.id)">
            <template #customTag>
              <div v-dompurify-html="tagDescription(person)" class="badge p-2"
                   :class="{'badge-warning': person.email && !person.phone, 'badge-success': person.phone, 'badge-danger': !person.email && !person.phone}"
                   style="position: absolute;bottom: -9px;right: 5%">
              </div>
            </template>

            <PersonContactDetails
                :key="'person-form' + person.id"
                v-model="data[index]"
                :objectId="person.id"
                :errors-post="errorsPost"
            />
          </ObjectCard>

        </div>
      </transition>
    </div>
  </QuestionBase>

</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {objectSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/objectSaveHelpers";
import {questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import {blankForms} from "@/components/common/questionnaires/question/helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";
import PersonContactDetails from "@/components/common/questionnaires/fieldsets/sub/people/PersonContactDetails";

export default {
  name: 'ClientPeopleAppendix',
  components: {
    PersonContactDetails,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    objectSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    blankForms
  ],
  props: {
    value: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      question: {
        title: `Please add contact information for those named below (if you don't have the details to hand, they can be added later):`,
        subTitle: 'The contact information is useful to your executors and will added to the appendix in your Will. Ideally, each person should have a mobile number and / or email address. Ignore those under the age of 18. Postal addresses are optional.',
        tip: null,
      },
      db: {
        saveLocation: 'client_person_appendix',
        saveField: 'people',
        deleteLocation: 'client_person',
        loadLocation: 'client_person_appendix',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'Add your person to proceed',
        customErrorMessageNewOpenForm: "Save person before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    tagDescription(person) {
      if (person.phone || person.email) {
        let phone = person.phone ? person.phone : 'No Phone'
        let email = person.email ? person.email : 'No Email'
        return `<i class="i-Old-Telephone font-weight-bold text-12 mr-2"></i>${phone}<i class="i-Email text-12 font-weight-bold ml-2 mr-2"></i>${email}`
      } else if (!person.phone && !person.email) {
        return 'No Contact Details'
      }
    },
    addSon() {
      this.form = null
      this.form = Object.assign({},
          this.personBlankForm)
      this.form.gender = 'male'
      this.form.relationship_to_client = 'son'
    },
    addDaughter() {
      this.form = null
      this.form = Object.assign({},
          this.personBlankForm)
      this.form.gender = 'female'
      this.form.relationship_to_client = 'daughter'
    }
  }
}
</script>
