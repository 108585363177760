<template>
  <div>
    <TypeFinancial
        v-model="form.assetType"
        :errors-post="errorsPost.assetType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Value
        v-model="form.assetValue"
        :errors-post="errorsPost.assetValue"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Owners
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />

    <OwnerOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />
    <Providers
        v-model="form.providers"
        :assetType="form.assetType"
        :errors-post="errorsPost.providers"
        :object-id="objectId"
        :no-save="noSave"
    />
    <Location
        v-model="form.assetLocation"
        :errors-post="errorsPost.assetLocation"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>
import Value from "../../../question/questions/clientAsset/Value";
import OwnerOtherDetails from "../../../question/questions/clientAsset/OwnerOtherDetails";
import Owners from "../../../question/questions/clientAsset/Owners";
import Location from "@/components/common/questionnaires/question/questions/clientAsset/Location";
import Providers from "@/components/common/questionnaires/question/questions/clientAsset/Providers";
import TypeFinancial from "@/components/common/questionnaires/question/questions/clientAsset/TypeFinancial";

export default {
  name: 'AssetAppendixNew',
  components: {TypeFinancial, Providers, Location, Owners, OwnerOtherDetails, Value},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
