<template>
  <div>
<!--    <FullName-->
<!--        :id="'name_first'+objectId"-->
<!--        :noSave="noSave"-->
<!--        :objectId="objectId"-->
<!--        :value-first="form.name_first"-->
<!--        :value-middle="form.name_middle"-->
<!--        :value-last="form.name_last"-->
<!--        :errors-post-first="errorsPost.name_first || []"-->
<!--        :errors-post-middle="errorsPost.name_middle || []"-->
<!--        :errors-post-last="errorsPost.name_last || []"-->
<!--        @inputFirst="form.name_first=$event"-->
<!--        @inputMiddle="form.name_middle=$event"-->
<!--        @inputLast="form.name_last=$event"-->
<!--    />-->

    <Email
        :id="'email'+objectId"
        v-model="form.email"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.email || []"
    />
    <Phone
        :id="'phone'+objectId"
        v-model="form.phone"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.phone || []"
    />

    <AddressPerson
        :id="'address'+objectId"
        v-model="form.address"
        :form="form"
        :errors-post="errorsPost.address || []"
        :object-id="objectId"
    />

  </div>
</template>

<script>
import {clientPersonOptions} from "../../../question/options/clientPersonOptions";

import Email from "../../../question/questions/clientPerson/Email";
import Phone from "../../../question/questions/clientPerson/Phone";
import AddressPerson from "../../../question/questions/clientPerson/AddressPerson";

export default {
  name: 'PersonContactDetails',
  mixins: [clientPersonOptions],
  components: {
   AddressPerson, Phone, Email
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    'form.relationship_to_client': {
      immediate: false,
      deep: false,
      handler(value) {
        if (this.childListOptions.includes(value)) {
          this.form.clients = []
          this.form.dependant = null
        } else {
          this.form.clients = [this.$store.getters.client.id]
          this.form.dependant = false
        }
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>