<template>
  <QuestionBase :question="question" :errors="[...jsErrors]" :valid="!!data.length" :id="db.saveField">
    <div class="question-box with-arrow mt-32">
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard
            v-for="(asset, index) in financialAssets"
            v-model="financialAssets[index]"
            :ref="'asset_other' + index"
            :id="'asset_other' + index"
            :key="'asset_other' + asset.id"
            :title="`${asset.assetType}`"
            type="asset-other"
            :loading="loading"
            @delete="deleteObject(asset.id)"
            @save="saveObject(null, 'asset_other' + index)"
        >
          <Component :is="formType" v-model="financialAssets[index]" :objectId="asset.id" :key="'asset-form' + asset.id" />

          <template #customTag>
            <slot name="customTag" :asset="asset"></slot>
          </template>

        </ObjectCard>

        <ObjectCard
            v-if="form"
            v-model="form"
            type="asset-add"
            title="New Financial Asset"
            :loading="loading"
            @delete="form=null"
            @save="saveObject(form, db.saveField)"
        >
          <Component :is="'AssetAppendixNew'" v-model="form" :noSave="true" :errors-post="errorsPost" />
        </ObjectCard>

        <b-button
            v-if="!form"
            @click="addAsset"
            class="btn-question w-100"
        >
          <i class="i-Home1 text-25 font-weight-800 mr-2">
          </i>
          Add Financial Asset
        </b-button>
      </div>
    </transition>
    </div>
  </QuestionBase>

</template>

<script>
import { objectSaveHelpers} from "../../objects/objectSaveHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { blankForms } from "../../../helpers/blankForms";
import { assetOptions } from "@/components/common/questionnaires/question/options/assetOptions";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";
import AssetAppendixNew from "@/components/common/questionnaires/fieldsets/sub/asset/AssetAppendixNew";
import AssetMortgage from "@/components/common/questionnaires/fieldsets/sub/asset/AssetMortgage";
import AssetEstatePlanning from "@/components/common/questionnaires/fieldsets/sub/asset/AssetEstatePlanning";
import AssetAppendix from "@/components/common/questionnaires/fieldsets/sub/asset/AssetAppendix";

export default {
  name: 'AssetsFinancial',
  components: {AssetAppendixNew, ObjectCard, QuestionBase, AssetMortgage, AssetEstatePlanning, AssetAppendix},
  mixins: [objectSaveHelpers, questionLoadHelpers, blankForms, assetOptions],
  props: {
    value: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      default: 'Whilst entirely optional, you can add details of where you hold savings and investments below:'
    },
    subTitle: {
      type: String,
      default: 'This can be useful to your executors when they are dealing with the administration of your estate. It is easy to update this information in the future if your financial situation changes.',
    },
    tip: {
      type: String,
      default: null
    },
    formType: {
      type: String,
      default: 'AssetFull'
    }
  },
  data() {
    return {
      question: {
        title:  this.title,
        subTitle:  this.subTitle,
        tip: this.tip,
      },
      db: {
        saveLocation: 'client_asset',
        saveField: 'assets',
        deleteLocation: 'client_asset',
        loadLocation: `client_asset`,
        syncMultipleObjectOnSave: false,
        fetchObjectsOnSave: true
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    financialAssets() {
      return this.data.filter(asset => this.providerRequiredAssets.includes(asset.assetType))
    }
  },
  methods: {
    addAsset() {
      this.form = null
      this.form = Object.assign({}, this.assetBlankForm)
    },
  }
}
</script>
