<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Financial Asset Information</h3>

    <div v-if="form.assets">
      <AssetsFinancial
          v-model="form.assets"
          ref="assetsOther"
          form-type="AssetAppendix"
      >
        <template v-slot:customTag="{asset}">
          <div v-if="assetTagDescription(asset)" v-dompurify-html="assetTagDescription(asset)"
               class="badge p-2"
               :class="assetTagClass(asset)"
               style="position: absolute;bottom: -9px;right: 5%">
          </div>
          <div v-else>

          </div>
        </template>
      </AssetsFinancial>
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {assetOptions} from "@/components/common/questionnaires/question/options/assetOptions";

import AssetsFinancial
  from "@/components/common/questionnaires/question/questions/clientProfile/assets/AssetsFinancial";

export default {
  name: 'AppendixClientAssets',
  mixins: [fieldsetHelpers, assetOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AssetsFinancial
  },
  data() {
    return {
      label: 'Financial Asset Information' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    assetTagDescription(asset) {
      if (this.providerRequiredAssets.includes(asset.assetType)) {
        if (!asset.providers.length) {
          return 'No provider details'
        }
        if (asset.providers.length) {
          return asset.providers.join(' ')
        }
      }
      return false
    },
    assetTagClass(asset) {
      if (this.providerRequiredAssets.includes(asset.assetType) && !asset.providers.length) {
        return 'badge-danger'
      }
      return 'badge-success'
    }
  }
};
</script>
